import { useState, useEffect, useRef } from 'react';
import {FiArrowLeft, FiArrowRight} from 'react-icons/fi'
import {CardUser} from './cardUser';
import label from '../../config/label.json';


/* Captura os usuarios no backend */
const getUsers = async () => {
  return await fetch(`${label.backend}/api/allUsers/${sessionStorage.getItem('key')}`)
  .then(response => response.json())
  .then(result => result)
  .catch(error => console.log('error', error));
}



/* Captura as cobranças do assas no backend */
const getAssinatura = async(assinaturaID)=>{
  var formdata = new FormData();
  formdata.append("assinaturaID", assinaturaID);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  return await fetch(`${label.backend}/api/assas/capturarCobranca`, requestOptions)
  .then(response => response.json())
  .then(result =>{return result})
  .catch(error => console.log('error', error));
}



/* Verica o stutus da assinatura do usuario */
const AssasAssinatura = ({ assas }) => {

  const [assinaturaStatus, setAssinaturaStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (assas) {
        const usersData = await getAssinatura(assas.assinaturaID);
        if (usersData && usersData.data) {
          const reversedData = usersData.data.reverse();
          for (const ass of reversedData) {
            if (ass.status !== 'RECEIVED' && ass.status === 'OVERDUE') {
              setAssinaturaStatus('Inadimplente');
              return;
            }
          }
        }
      }
      setAssinaturaStatus('Em Dia');
    };

    fetchData();
  }, [assas]);

  console.log(assas);

  return <span className='assinatura-users' tag={assinaturaStatus}>{assinaturaStatus}</span>;
};




/* Lista os usuarios cadastrados na plataforma */
const ListUsers = ({users, setCardUser, setuserDados}) => {





  const setStatus  = (status) =>{
   
    switch(status){
      
      case 0:
        status = 'Em Teste';
      break;

      case 1:
        status = 'Gratuito';
      break;
            
      case 2:
        status = 'Assinante';
      break;
      
      case 3:
        status = 'Bloqueado';
      break;

      default:
        status = 'Indefinido';
      break;

      
    }

    return (
    <span> {status} </span>)
    ;
  }
   

  const userList = users.map(user => (
          

    <div key={user.id} className='list-Users' onClick={()=> {setuserDados(user); setCardUser(true)}}>
            
      <div className='picture-Users'>
        <img style={{width:"50px", height: "50px", borderRadius:"50%"}} src={user.picture || 'images/defaulUser.svg'}/>
        <span>{user.nome}</span>
      </div>
            
      <div className='whats-Users'> <span>{user.whatsapp}</span> </div>

      <div className='status-Users'> {setStatus(user.status)} </div>

      <div className='ass-Users'>
        { JSON.parse(user.idAssas) !== null? <AssasAssinatura  assas={JSON.parse(user.idAssas)}/> : 
        <span className='assinatura-users' tag={'Não Cadastrado'}>Não Cadastrado</span>}
      </div>

      <div className='afiado-Users'> <span>{user.afiliado != 'false'? user.afiliado : '' }</span> </div>


      
    </div>
  
  ));
  
  return userList;
  
};
  


/* Cria a dinamica de navegar entre as paginas de usuarios criadas */
const moveList = (direcao, users, setList, indexList, setIndex, textIndexPage, setPage, btnProximo) =>{
  
  let move = 10;

  btnProximo.current.classList.remove('disableMove');


  if(direcao == 'proximo'){
     
    if(Math.ceil(users.length/move) == textIndexPage){
    
      btnProximo.current.classList.add('disableMove');
    
      return;
    }
   
    setList(users.slice(indexList, indexList + move));

    setIndex(indexList + move);

    setPage(textIndexPage + 1)
  }
  else{

    if(textIndexPage == 1)return;

    setList(users.slice(indexList - (move + move), indexList - move));

    setIndex(indexList - move);

    setPage(textIndexPage - 1);
  }


}




/* Pesquisa por um usuario na listagem */
const pesquisaUser = (pesquisa, users, setList) =>{
  
  let usersPesquisados = [];

  /* Verifica se a pesquisa esta sendo utilizada, caso sim remova o footer */
  if(pesquisa.length == 0){
    window.location.reload();
  }
  else{
    document.querySelector('.nav-list')?.remove();
  }



  for(let user of users){
    if(

      user.login?.toLowerCase().includes(pesquisa.toLowerCase()) ||
      user.nome?.toLowerCase().includes(pesquisa.toLowerCase()) ||
      user.whatsapp?.toLowerCase().includes(pesquisa.toLowerCase()) ||
      user.afiliado?.toLowerCase().includes(pesquisa.toLowerCase())
        
    ){
      usersPesquisados.push(user);
    }
  }

  setList(usersPesquisados);

}




/* Função principal que retorna a listagem de usuarios */
export default  function Main(){
  
  
  let [users, setUsers] = useState(['']);

  let [list, setList] = useState(null);

  let [indexList, setIndex] = useState(10);

  const [textIndexPage, setPage] = useState(1);


  const btnProximo = useRef(null);

  // input user pesquisar
  const [pesquisa, setPesquisa] = useState('');


  //Modal do cardUser
  const [cardUser, setCardUser] = useState(false);
  const [userDados, setuserDados] = useState(null);
      
  /* Aguarda a requisição dos usuarios ser completada */
  useEffect(() => {
    const fetchData = async () => {
      const usersData = await getUsers();
      console.log(usersData.response.users);
      setUsers(usersData.response.users);
    };
    fetchData();
  }, []);


  /* So deixa o script proseguir quando o users obter a resposta */
  if(users == '')return;
   
  
  const assinantes = () =>{

    let assinantes = [];

    users.forEach((user)=>{

      if(user.idAssas){
        assinantes.push(user);
      }

    });

     
    setUsers(assinantes);
    document.querySelector('.quantClient').innerHTML = `${assinantes.length} Assinantes`;

  }


  /* Inicia a listagem dos usuarios*/
  const Main = (

    <main>

      <div className="main">

        <div className="main-pesquisa">
          <input value={pesquisa} onChange={(e)=> setPesquisa(e.target.value)} placeholder="🔎 Pesquisar por Nome, Login, Telefone ou Afiliação"/>
          <button onClick={()=> pesquisaUser(pesquisa, users, setList)} style={{backgroundColor:'#008069'}}>Buscar</button>
          <button style={{backgroundColor:'red'}} onClick={()=> window.location.reload()}>Limpar Busca</button>
          <button onClick={assinantes}>Listar Assinantes</button>
          <span className='quantClient'>{users.length} Clientes Cadastrados</span>
        </div>

        <div className='list'><ListUsers users={list? list : users.slice(0, indexList)}  setCardUser={setCardUser}  setuserDados={setuserDados}/></div>
             
        <div className='nav-list'>
          <button className={textIndexPage == 1? 'disableMove' : '' } onClick={()=>moveList('anterior', users, setList, indexList, setIndex, textIndexPage,setPage, btnProximo)}><FiArrowLeft /> Anterior</button>
          <span>Pagina {textIndexPage}</span>
          <button ref={btnProximo} onClick={()=>moveList('proximo', users, setList, indexList, setIndex, textIndexPage, setPage, btnProximo)}>Proximo <FiArrowRight /></button>
        </div>
            
      </div>


      {cardUser && <CardUser user={userDados} setCardUser={setCardUser}/>}

    </main>
  );
    
  
  return Main;
    
}